import throttle from 'raf-throttle'
import debounce from 'lodash.debounce'
import type {
  default as Mediator,
  MediatorUserInterface
} from 'widgetly/lib/Mediator'
import type Widget from 'widgetly/lib/Widget'
import EmbedLayout from 'widgetly/lib/layouts/EmbedLayout'
import {cleanUrlQuery, formatUrl, getUrlParams} from '@rambler-id/url'
import {getSessionToken} from 'common/api/id'
import {getFingerPrintUserId} from 'common/api/ssp'
import {Position} from 'common/api/records'
import type {OptionsDataSet} from 'common/types/options'
import {getCookie, setCookie} from 'utils/cookie'
import {activateTop100, sendCustomVars} from 'utils/metrics'
import {
  createViewportManager,
  globalViewportEvents,
  ViewportManager,
  getVisibleArea,
  getVisibleAreaXYZ,
  type Rect
} from 'utils/dom'
import {getRecordId} from 'utils/record'
import type {MediatorProperties} from 'common/types/provider'
import {ASPECT_RATIO} from 'common/constants'
import {Events} from './events'
import type {Options} from './options'
import styles from './styles.scss'

interface PlayerWidget extends Widget<Options> {
  getSessionToken?: typeof getSessionToken
  playerId?: string
  activateTop100?: typeof activateTop100
  sendCustomVars?: typeof sendCustomVars
  dockingX?: 'left' | 'right'
  dockingY?: 'top' | 'bottom'
  closeDockButton?: SVGSVGElement
  closeDelayTimeout?: number
  viewportManager?: ViewportManager
  getVisibleArea?(): Rect
  getEmbedVisibleArea?(): Rect
  getClientHeight?(): number
  subscribePageScroll?(callback: (rect: Rect) => void): () => void
  subscribePageOrientationChange?(callback: () => void): () => void
  unsubscribePageScroll?: () => void
  unsubscribePageOrientationChange?: () => void
  mediator: MediatorUserInterface<Record<string, unknown>, MediatorProperties>
}

const VERSION = process.env.VERSION
const CDN_ORIGIN = process.env.CDN_ORIGIN
const CDN_PREFIX = process.env.CDN_PREFIX

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const LAZY_OFFSET = 300
const SCROLL_OFFSET = -50
const SCROLL_DURATION = 300
const DOCKING_OFFSET = 10
const UNDOCK_TIMEOUT = 1000

export const name = 'Player'

const createButton = (
  size: number,
  hoverable: boolean,
  style: Record<string, string>
): SVGSVGElement => {
  const icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  const styleEntries: [string, string][] = [
    ['width', `${size}px`],
    ['height', `${size}px`]
  ]

  Object.keys(style).forEach((property) => {
    styleEntries.push([property, style[property]])
  })
  icon.setAttributeNS(
    null,
    'class',
    [styles.close, hoverable && styles.hoverable].filter(Boolean).join(' ')
  )
  icon.setAttributeNS(
    null,
    'style',
    styleEntries.map((item) => item.join(':')).join(';')
  )
  icon.setAttributeNS(null, 'viewbox', '0 0 20 20')

  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')

  path.setAttributeNS(
    null,
    'd',
    'M20 0v20M0 0v20M14.18 6.88 11.06 10l3.12 3.12a.5.5 0 0 1 0 .7l-.36.36a.5.5 0 0 1-.7 0L10 11.06l-3.12 3.12a.5.5 0 0 1-.7 0l-.36-.36a.5.5 0 0 1 0-.7L8.94 10 5.82 6.88a.5.5 0 0 1 0-.7l.36-.36a.5.5 0 0 1 .7 0L10 8.94l3.12-3.12a.5.5 0 0 1 .7 0l.36.36a.5.5 0 0 1 0 .7'
  ) //eslint-disable-line max-len
  icon.appendChild(path)

  return icon
}

const parseDataset = (location: Location, referrer: string): OptionsDataSet => {
  const {href, hash, protocol, hostname} = location

  const referrerDataset = referrer
    ? (getUrlParams(referrer) as OptionsDataSet)
    : null
  const origin = formatUrl({protocol, host: hostname})
  const queryDataset = getUrlParams(href) as OptionsDataSet
  const hashDataset = getUrlParams(`${origin}${hash.replace(/^#/, '?')}`)

  return {...referrerDataset, ...queryDataset, ...hashDataset}
}

const parseRecordData = (
  dataset: OptionsDataSet
): Record<string, unknown> | undefined => {
  try {
    return JSON.parse(atob(dataset.rprecord) || '')
  } catch {}
}

const parseOptions = (
  params: Options,
  dataset: OptionsDataSet,
  recordData?: Record<string, unknown>
): OptionsDataSet => {
  const options: OptionsDataSet = cleanUrlQuery({
    ...params,
    initialId: params.id,
    id: (recordData?.recordId as number | string) ?? params.id,
    debug:
      params.debug ??
      (dataset.hasOwnProperty('rpdebug') ||
        dataset.hasOwnProperty('rpversion')),
    lazy: params.lazy?.toString() === 'true',
    lazyOffset: Number(params.lazyOffset) || LAZY_OFFSET
  })

  // remove functions that should be used by magic transport
  for (const key in options) {
    // serialize object params
    if (typeof options[key] === 'object') {
      options[key] = JSON.stringify(options[key])
    }

    // remove functions that should be used by magic trasport
    if (typeof options[key] === 'function') {
      delete options[key]
    }
  }

  return options
}

export const defineWidget = (
  mediator: Mediator<Record<string, unknown>, MediatorProperties>
): void => {
  mediator.defineWidget(
    {
      name,

      async initialize(this: PlayerWidget): Promise<void> {
        this.startTime = Date.now()
        this.params.referrer = this.params.referrer ?? window.location.href
        this.params.aspectRatio = this.params.aspectRatio ?? ASPECT_RATIO
        this.properties.id = this.id

        const dataset = parseDataset(window.location, this.params.referrer)
        const recordData = parseRecordData(dataset)
        const options = parseOptions(this.params, dataset, recordData)

        const iFrameParams = new URLSearchParams(options).toString()

        await this.whenContainerInViewport({
          lazy: options.lazy,
          offset: options.lazyOffset
        })

        this.iframe = this.createIFrame(
          `${CDN_ORIGIN}${CDN_PREFIX}/${VERSION}/player.html#${iFrameParams}`
        )
        this.iframe.element.setAttribute('allow', 'autoplay; fullscreen')
        this.iframe.element.setAttribute('allowfullscreen', '')
        this.iframe.element.title = this.name

        this.layout = new EmbedLayout()
        this.layout.show()
        this.layout.showLoading()
        // apply temporary fixed styles during player loading
        this.layout.element.style.height = `${
          this.params.height ??
          this.container.element.offsetWidth / this.params.aspectRatio
        }px`
        this.layout.element.style.backgroundColor = 'black'
        this.layout.element.style.position = 'relative'

        this.layout.addToDOM(this.container)
        this.layout.setContent(this.iframe)

        try {
          await this.iframe.initialize()
          // таймаут 0 нужен для вызова после завершения initialize, когда произойдет подписка на события
          setTimeout(() => this.iframe.provider.emit(Events.PLAYER_READY), 0)
          // enable auto resize
          this.layout.element.style.height = ''
          this.layout.element.style.backgroundColor = ''
          this.layout.hideLoading()
          this.emit('widgetShown')

          if (recordData) {
            const initialId = getRecordId(this.params.id)

            if (initialId && initialId === recordData.id) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.iframe?.provider.scrollTo(SCROLL_OFFSET, SCROLL_DURATION)
            }
          }
        } catch (error) {
          this.destroy()
          throw error
        }
      },

      destroy(this: PlayerWidget): void {
        this.unsubscribePageScroll?.()
        this.unsubscribePageOrientationChange?.()
        this.viewportManager?.destroy()

        if (this.playerId) {
          this.mediator.stopPlaying(this.playerId, this.params.dockingParentId)
        }
      }
    },
    {
      getSessionToken,
      getCookie,
      setCookie,
      activateTop100,
      sendCustomVars,
      getFingerPrintUserId,

      getStartTime() {
        return this.startTime
      },

      getVisibleArea(this: PlayerWidget): Rect {
        return getVisibleAreaXYZ(this.iframe.element)
      },

      getEmbedVisibleArea(this: PlayerWidget): Rect {
        return getVisibleArea(this.layout.element)
      },

      getClientHeight(this: PlayerWidget): number {
        return document.documentElement.clientHeight
      },

      setPlayerId(this: PlayerWidget, playerId: string) {
        this.playerId = playerId
      },

      subscribePageOrientationChange(this: PlayerWidget, callback: () => void) {
        const onOrientationChange = debounce((): void => {
          callback()
        }, SCROLL_DURATION)

        window.addEventListener('orientationchange', onOrientationChange)

        return (): void => {
          window.removeEventListener('orientationchange', onOrientationChange)
        }
      },

      subscribePageScroll(
        this: PlayerWidget,
        callback: (rect: Rect) => void
      ): () => void {
        const onScroll = throttle((): void => {
          if (this.getEmbedVisibleArea && !this.mediator.hasFullscreen()) {
            callback(this.getEmbedVisibleArea())
          }
        })

        globalViewportEvents.on('change', onScroll)

        return (): void => {
          globalViewportEvents.removeListener('change', onScroll)
        }
      },

      subscribeVisibleAreaChange(
        this: PlayerWidget,
        callback: (rect: Rect) => void
      ) {
        if (!this.viewportManager) {
          const updateViewport = (): void => {
            this.emit('viewportChange')
          }

          this.viewportManager = createViewportManager(
            this.iframe.element,
            updateViewport
          )
        }

        const onVisibleAreaChange = (): void => {
          if (this.getVisibleArea && !this.mediator.hasFullscreen()) {
            callback(this.getVisibleArea())
          }
        }

        this.on('viewportChange', onVisibleAreaChange)

        return (): void => {
          this.removeListener('viewportChange', onVisibleAreaChange)
        }
      },

      // eslint-disable-next-line sonarjs/cognitive-complexity
      dock(
        this: PlayerWidget,
        {
          docking = Position.LEFT_DOWN,
          dockingWidth = 400,
          dockingOffsetX: offsetX,
          dockingOffsetY: offsetY,
          dockingCloseDelay,
          dockingParentId,
          onCloseClick,
          buttonSize,
          buttonGap,
          buttonHoverable,
          buttonStyle
        }: {
          docking: string
          dockingWidth: number
          dockingOffsetX?: null | number
          dockingOffsetY?: null | number
          dockingCloseDelay?: null | number
          dockingParentId?: string
          color: string
          onCloseClick: () => void
          buttonSize: number
          buttonGap: number
          buttonHoverable: boolean
          buttonStyle: Record<string, string>
        }
      ): void {
        const dockingOffsetX = offsetX ?? DOCKING_OFFSET
        const dockingOffsetY = offsetY ?? DOCKING_OFFSET
        let resultDockingWidth: number
        let resultDockingHeight: number
        let dockingOverflowY: string | null
        let resultDockingOffsetX: number
        let resultClientWidth: number

        const dockingParent = dockingParentId
          ? document.querySelector<HTMLElement>(dockingParentId)
          : null

        const setCloseDockButtonPosition = (): void => {
          if (this.closeDockButton && this.dockingX) {
            this.closeDockButton.style[this.dockingX] = `${
              resultDockingOffsetX + resultDockingWidth + buttonGap
            }px`
          }
        }

        const createCloseDockButton = (): void => {
          const {parentElement} = this.iframe.element

          if (!parentElement) {
            return
          }

          const button = (this.closeDockButton = createButton(
            buttonSize,
            buttonHoverable,
            buttonStyle
          ))

          button.onclick = () => onCloseClick()
          setCloseDockButtonPosition()

          if (dockingCloseDelay) {
            button.classList.add(styles.hidden)
            this.closeDelayTimeout = window.setTimeout(() => {
              button.classList.remove(styles.hidden)
            }, dockingCloseDelay * 1e3)
          }

          this.layout.element.style.overflow = 'visible'
          parentElement.style.overflow = 'visible'
          parentElement.appendChild(button)
        }

        const setContainerSize = (): void => {
          const {aspectRatio = ASPECT_RATIO} = this.params
          const {height: layoutHeight} =
            this.layout.element.getBoundingClientRect()
          const {clientWidth} = document.documentElement

          resultDockingWidth = Math.min(
            clientWidth - 2 * dockingOffsetX - buttonGap - buttonSize,
            aspectRatio < 1 ? dockingWidth * aspectRatio : dockingWidth
          )
          resultDockingHeight = resultDockingWidth / aspectRatio

          this.dockingX = ~docking.indexOf('right') ? 'right' : 'left'
          this.dockingY = ~docking.indexOf('up') ? 'top' : 'bottom'
          clearTimeout(this.resetLayoutHeightTimeout)

          // cache current height to avoid resize embeded container
          this.layout.element.style.height = `${layoutHeight}px`
          this.layout.element.style.position = ''

          this.iframe.element.style.zIndex = '16777271'
          this.iframe.element.style.width = `${resultDockingWidth}px`
          this.iframe.element.style.minWidth = `${resultDockingWidth}px`
          this.iframe.element.style.maxWidth = `${resultDockingWidth}px`
          this.iframe.element.style.maxHeight = `${resultDockingHeight}px`
        }

        const getOverflowY = (): 'top' | 'bottom' | null => {
          const parentRect = dockingParent?.getBoundingClientRect()
          const {clientHeight} = document.documentElement

          const minY =
            this.dockingY === 'bottom'
              ? clientHeight - resultDockingHeight - dockingOffsetY * 2
              : 0
          const maxY =
            this.dockingY === 'top'
              ? resultDockingHeight + dockingOffsetY * 2
              : clientHeight

          if (!dockingParent || !parentRect) {
            return null
          }

          if (minY < parentRect.top) {
            return 'top'
          }

          if (maxY > parentRect.bottom) {
            return 'bottom'
          }

          return null
        }

        const setDockingPosition = (): void => {
          const parentRect = dockingParent?.getBoundingClientRect()
          const {clientWidth} = document.documentElement

          const overflowY = getOverflowY()
          const viewportIsChanged =
            overflowY !== dockingOverflowY || clientWidth !== resultClientWidth

          if (
            !viewportIsChanged ||
            !this.dockingX ||
            !this.dockingY ||
            !this.closeDockButton
          ) {
            return
          }

          const positionY = overflowY == null ? this.dockingY : overflowY
          let parentOffsetX = 0

          if (parentRect) {
            parentOffsetX = docking.includes('right')
              ? clientWidth - parentRect.right
              : parentRect.left
          }

          resultDockingOffsetX =
            overflowY == null ? dockingOffsetX : dockingOffsetX - parentOffsetX

          resultClientWidth = clientWidth

          if (~docking.indexOf('middle')) {
            if (
              clientWidth >
              resultDockingWidth + 2 * (dockingOffsetX + buttonSize + buttonGap)
            ) {
              resultDockingOffsetX = (clientWidth - resultDockingWidth) / 2
            } else if (
              clientWidth >
              resultDockingWidth + 2 * dockingOffsetX + buttonSize + buttonGap
            ) {
              resultDockingOffsetX =
                (clientWidth - resultDockingWidth - buttonSize - buttonGap) / 2
            }
          }

          this.iframe.element.style.position =
            overflowY == null ? 'fixed' : 'absolute'
          this.iframe.element.style[this.dockingX] = `${resultDockingOffsetX}px`
          this.iframe.element.style[positionY] = `${dockingOffsetY}px`
          this.iframe.element.style[positionY === 'top' ? 'bottom' : 'top'] = ''
          this.closeDockButton.style.position =
            overflowY == null ? '' : 'absolute'
          this.closeDockButton.style[positionY] =
            positionY === 'top'
              ? `${dockingOffsetY}px`
              : `${dockingOffsetY + resultDockingHeight - buttonSize}px`
          this.closeDockButton.style[positionY === 'top' ? 'bottom' : 'top'] =
            ''
          dockingOverflowY = overflowY
        }

        const containerResize = (): void => {
          dockingOverflowY = ''

          setContainerSize()
          setDockingPosition()
          setCloseDockButtonPosition()
        }

        const containerScroll = (): void => {
          setDockingPosition()
          setCloseDockButtonPosition()
        }

        setContainerSize()
        setDockingPosition()
        createCloseDockButton()

        this.unsubscribePageOrientationChange =
          this.subscribePageOrientationChange?.(containerResize)

        this.unsubscribePageScroll = this.subscribePageScroll?.(containerScroll)
      },

      async undock(this: PlayerWidget): Promise<void> {
        this.iframe.element.style.width = ''
        this.iframe.element.style.minWidth = ''
        this.iframe.element.style.maxWidth = ''
        this.iframe.element.style.maxHeight = ''

        if (this.dockingX) {
          this.iframe.element.style[this.dockingX] = ''
        }

        if (this.dockingY) {
          this.iframe.element.style[this.dockingY] = ''
        }

        this.iframe.element.style.position = ''
        this.iframe.element.style.zIndex = ''

        const {parentElement} = this.iframe.element

        if (parentElement) {
          this.layout.element.style.overflow = ''
          this.layout.element.style.position = 'relative'
          parentElement.style.overflow = ''

          if (this.closeDockButton) {
            parentElement.removeChild(this.closeDockButton)
            delete this.closeDockButton
          }
        }

        clearTimeout(this.resetLayoutHeightTimeout)
        if (this.closeDelayTimeout) window.clearTimeout(this.closeDelayTimeout)
        this.unsubscribePageScroll?.()
        this.unsubscribePageOrientationChange?.()
        await new Promise<void>((resolve) => {
          this.resetLayoutHeightTimeout = window.setTimeout(async () => {
            this.layout.element.style.height = ''
            // trigger viewport change after styles are applied
            await this.emit('viewportChange')
            resolve()
          }, UNDOCK_TIMEOUT)
        })
      }
    }
  )
}

export {Options, Events}
